import { faSearch, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { getPedidoById } from '../../shared/services/pedidos';
import { toast } from 'react-toastify';
import moment from 'moment';

export default function Step1({ pedidos, setPedidos }) {
  const [codigoPedido, setCodigoPedido] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const inputCodigo = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
        setCodigoPedido('');

        if (inputCodigo.current) {
            inputCodigo.current.focus();
            inputCodigo.current.value = '';
        }
    }, 5000);

    return () => {
      clearInterval(interval);
    }
}, []);

  const validateInput = (input) => { return /^[0-9]+$/.test(input); };

  const handleEnterInput = async (e) => {
    if (e.key === 'Enter') {
      if (!validateInput(codigoPedido)) {
        showErrorMessage('El código del pedido debe contener solo números.');
        return;
      }

      setIsLoading(true);
      showErrorMessage('');

      if ( pedidos.find(
          (pedido) => pedido.pedido.id.toString() === codigoPedido.toString())
      ) {
        showErrorMessage(`El pedido: ${codigoPedido} ya está en la lista.`);
        setIsLoading(false);
        return;
      }
      const pedido = await getPedido();

      if (pedido !== null) {
        if(pedido.estado_id !== 3) {
          toast.error('¡ERROR! Este pedido no está completado!');
          return;
        }
        
        const pedido_to_add = { aceptado: false, pedido: pedido };
        setPedidos([...pedidos, pedido_to_add]);
      }

      setIsLoading(false);
    }
  };

  const getPedido = async () => {
    try {
      const res = await getPedidoById(codigoPedido);

      if (res.success === true) {
        return res.pedido;
      }

      throw Error('Failed to fetch pedido.');
    } catch (error) {
      const msg_error =
        error.response?.data?.message ||
        `No se encontró el pedido: ${codigoPedido}.`;
      showErrorMessage(msg_error);
      return null;
    }
  };

  const showErrorMessage = (text) => {
    setErrorMessage(text);
    setCodigoPedido('');
  };

  const handleRemovePedido = (index) => {
    const newPedidos = [...pedidos];
    newPedidos.splice(index, 1);
    setPedidos(newPedidos);

    if (newPedidos.length === 0) {
      showErrorMessage('');
    }
  };

  return (
    <div className='w-100 mt-3'>
      <h1>Recogida de pedidos</h1>
      <p className='mt-3'>
        Con el lector de código de barras, escanea los pedidos. Una vez veas
        reflejado en el campo de texto inferior el número/s de pedido/s, haz
        click en siguiente.
      </p>

      <div className='w-100 d-flex align-items-center justify-content-center my-4'>
        <div className="col-12 col-md-6">
            <div className="position-relative">
              <input 
                  type="text" 
                  className="form-control" 
                  defaultValue={codigoPedido} 
                  id="filterBy" 
                  placeholder="Escanear código de barras"
                  onKeyDown={(e) => handleEnterInput(e)}
                  onChange={(e) => setCodigoPedido(e.target.value)}
                  ref={inputCodigo}
              />

              {isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} size="2x" spin />
                ) : (
                  <FontAwesomeIcon 
                    icon={faSearch} 
                    color="#215732" 
                    className="icon" 
                    style={{cursor: 'pointer'}} 
                    onClick={() => handleEnterInput({ key: 'Enter' })}
                />
              )}
              
          </div>

          { (errorMessage) &&
            <div className="form-invalid">
                {errorMessage}
            </div>
          }
        </div>
      </div>

      <div className='w-100 table-responsive'>
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>presupuesto</th>
              <th className='text-center'>fecha</th>
              <th>piezas</th>
              <th></th>
            </tr>
          </thead>

            {pedidos?.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan={5} className="text-center">
                    No hay pedidos escaneados
                  </td>
                </tr>
              </tbody>
            ) : (
              pedidos?.map((pedido, index) => {
                const piezasValues = Object.values(pedido.pedido.piezas)
                const piezasRows = piezasValues.map((pieza, i) => {
                  
                  const codigoPedido = i === 0 ? <td rowSpan={piezasValues.length + 1} width="8%">
                    {pedido.pedido.codigo_pedido}
                  </td> : null
                  const presupuesto = i === 0 ? <td rowSpan={piezasValues.length + 1} width="8%">
                    {pedido.pedido.numero_presupuesto}
                  </td> : null
                  const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
                    {moment(pedido.pedido.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                  </td> : null
                  
                  const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                    <span title="Eliminar" onClick={() => handleRemovePedido(i)} style={{cursor: 'pointer'}}>
                        <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" />
                    </span>
                  </td> : null
                        
                  return (
                    <tr key={i}>
                      {codigoPedido}
                      {presupuesto}
                      {fecha}
                      <td>
                        <div className='w-100 d-flex align-items-center'>
                          <div className='flex-grow-1 d-flex flex-column'>
                            <span>{pieza.nombre}</span>
                            <span className='fw-bold'>#{pieza.id_referencia_plataforma}</span>
                            <span>REF: {pieza.referencia_pieza}</span>
                          </div>
                        </div>
                      </td>
                      {acciones}
                    </tr>
                  )
                })

                return (
                  <tbody key={index}>
                    {piezasRows}
                  </tbody>
                )
              })
            )
          }
        </table>
      </div>
    </div>
  );
}
