import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import './styles/main.scss';
import Login from './views/Login';
import Main from './views/Main';
import useToken from './useToken';
import { useEffect } from "react";
import useVersionCheck from "./hooks/useVersionCheck";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, setToken, userRol } = useToken();
  const versionCheck = useVersionCheck();

  useEffect(() => {
    registerServiceWorker();
  }, [])

  useEffect(() => {
    
  }, [versionCheck])

  const registerServiceWorker = async () => {
    let newWorker;

    if('serviceWorker' in navigator) {
      await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/serviceWorker.js`, { scope: '/'})
      .then(reg => {
        console.log('Service Worker registered!');

        reg.addEventListener('updatefound', () => {
          // A wild service worker has appeared in reg.installing!
          newWorker = reg.installing;
          newWorker.addEventListener('statechange', () => {
            // Has network.state changed?
            switch (newWorker.state) {
              case 'installed':
                if (navigator.serviceWorker.controller) {
                  window.localStorage.setItem('version-update-needed', 'true');
                  dispatch({type: 'reload/set', payload: true});
                }
                // No update available
                break;
              default: return null;
            }
          });
        });
      })
      .catch((e) => {
        console.error(`Service Worker failed: ${e}`);
      });
      
      navigator.serviceWorker.ready.then((registration) => {
        console.log('Service Worker ready!');
      })
    }
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.clear();
    navigate('/login', { replace: true });
  }

  return (
    <Routes>
      <Route path="/" element={
          !token ? 
            <Login setToken={setToken} /> 
          : <Main onLogout={handleLogout} userRol={userRol} />
        }
      ></Route>

      <Route path="/login" element={
          !token ? 
            <Login setToken={setToken} /> 
          : <Main onLogout={handleLogout} userRol={userRol} />
        }
      ></Route>

      <Route path='*' exact={true} element={
        !token ? 
            <Login setToken={setToken} /> 
          : <Main onLogout={handleLogout} userRol={userRol} />
      } />
      <Route path='*' exact={true} element={<Navigate to="/" />}></Route>
    </Routes>
  );
}

export default App;
