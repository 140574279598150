import { Schedule } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import LectorModal from '../shared/components/modals/LectorInput';
import PedidosTable from './PedidosTable';
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboard } from '../../../../redux/features/dashboard';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

export default function Dashboard() {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => {
      return state.dashboard.entity
  });
  const loading = useSelector(state => {
    return state.loading
  })
  const INTERVAL_REFRESH = 15 * 60 * 1000;
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const initialActiveTab = currentUser.rol_id === 2 ? 'individual' : 'general';
  const [intervalId, setIntervalId] = useState(null);
  const [tab, setTab] = useState(initialActiveTab);
  const [lectorModalOpen, setLectorModalOpen] = useState(false);

  useEffect(() => {
    fetchNewPedidos();
    resetInterval();

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {

  }, [dashboard]);

  const fetchNewPedidos = async () => {
    if(currentUser.rol_id === 2) {
      dispatch(fetchDashboard(1, 2, currentUser.id))
    } else {
      dispatch(fetchDashboard(1, 1))
    }
  };

  const handleChangeTab = (e, tab) => {
    setTab(tab);

    if(tab === 'general') {
      dispatch(fetchDashboard(1, 1))
    } else if(tab === 'individual') {
      dispatch(fetchDashboard(1, 2, currentUser.id))
    } else {
      dispatch(fetchDashboard(1, 3))
    }
  }

  const handleManualRefresh = () => {
    fetchNewPedidos().then(() => {
      resetInterval();
    });
  };

  const handleOpenLectorModal = () => {
    setLectorModalOpen(true);
    clearInterval(intervalId);
  };

  const handleCloseLectorModal = () => {
    setLectorModalOpen(false);
    setTimeout(async () => {
      resetInterval();
    }, 2000);
  };

  const resetInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    const newIntervalId = setInterval(() => {
      fetchNewPedidos()
    }, INTERVAL_REFRESH);
    setIntervalId(newIntervalId);
  };

  const formatTextLastUpdated = () => {
    return dashboard?.last_update
      ? moment(dashboard?.last_update).format('HH:mm:ss - DD/MM')
      : 'N/A';
  };

  return (
    <div className='content'>
      <h1>Dashboard</h1>

      <div className="d-flex align-items-center justify-content-between">
        <div>
          <button
            className="btn btn-primary me-3 mb-4"
            onClick={handleOpenLectorModal}
          >
            Escanear código pedido
          </button>
        </div>

        <div>
          <button
            className="btn btn-primary mb-2"
            style={{ width: '100%' }}
            onClick={handleManualRefresh}
          >
            Refrescar pedidos
          </button>
          <div className="d-flex flex-row justify-content-center">
            <Schedule></Schedule>
            <span className="bg-secondary ms-2">{formatTextLastUpdated()}</span>
          </div>
        </div>
      </div>

      <div className='w-100'>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="Dashboard">
              { (currentUser.rol_id !== 2) ?
                <Tab label={
                    <div className='d-flex align-items-center'>
                      <span>General</span>
                      { tab === 'general' && 
                        <span className="badge ms-2">
                          { (loading == true) ?
                            <FontAwesomeIcon icon={faSpinner} size="1x" spin />
                            :
                            dashboard?.pedidos?.count
                          }
                        </span> 
                      }
                    </div>
                  }
                  value="general" />
               : null
              }
              { (currentUser.rol_id === 2) ?
                <Tab label={
                    <div className='d-flex align-items-center'>
                      <span>Individual</span>
                      { tab === 'individual' && 
                        <span className="badge ms-2">
                          { (loading == true) ?
                            <FontAwesomeIcon icon={faSpinner} size="1x" spin />
                            :
                            dashboard?.pedidos?.count
                          }
                        </span>
                      }
                    </div>
                  }
                  value="individual" />
                : null
              }
              <Tab label={
                <div className='d-flex align-items-center'>
                  <span>Histórico de pedidos</span>
                  { tab === 'historico' && 
                    <span className="badge ms-2">
                      { (loading == true) ?
                        <FontAwesomeIcon icon={faSpinner} size="1x" spin />
                        :
                        dashboard?.pedidos?.count
                      }
                    </span>
                  }
                </div>
              } 
              value="historico" />
          </TabList>
          </Box>
          <TabPanel value="general">
            <PedidosTable 
              id={'general'}
              lastUpdated={dashboard?.last_update}
              pedidos={dashboard?.pedidos?.rows}
              isAdmin={currentUser.rol_id === 1}
            />
          </TabPanel>
          <TabPanel value="individual">
            <PedidosTable
              id={'individual'}
              lastUpdated={dashboard?.last_update}
              pedidos={dashboard?.pedidos?.rows}
              userId={currentUser.id}
              isAdmin={currentUser.rol_id === 1}
            />
          </TabPanel>
          <TabPanel value="historico">
            <PedidosTable 
              id={'historico'}
              lastUpdated={dashboard?.last_update}
              pedidos={dashboard?.pedidos?.rows}
              isAdmin={currentUser.rol_id === 1}
            />
          </TabPanel>
        </TabContext>
      </div>

      <LectorModal
        state={lectorModalOpen}
        width="md"
        onClose={handleCloseLectorModal}
      />
    </div>
  )
}
