import API from '../index';

export const API_USERS = {
  get: async () => {
    return await API.get(`/usuario`).then((res) => res.data);
  },
  getById: async (id) => {
    return await API.get(`/usuario/${id}`).then((res) => res.data);
  },
  getByTypeId: async (id) => {
    return await API.get(`/usuario/tipo/${id}`).then((res) => res.data);
  },
  changeStateActive: async (id) => {
    return await API.put(`/usuario/${id}`).then((res) => res.data);
  },
};
