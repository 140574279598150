import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function Loading() {
  return (
    <div className='loading-container'>
      <div className="loading-content">
        <FontAwesomeIcon
          style={{ color: '#215732' }}
          icon={faSpinner}
          size="2x"
          spin
        />
        <h1 className='loading-message'>Cargando...</h1>
      </div>
    </div>
  );
}
