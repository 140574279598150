import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Grid,
} from '@mui/material';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const steps = ['Lector de pedidos', 'Datos', 'Firma'];

export default function Carrier() {
  const [activeStep, setActiveStep] = useState(0);
  const [pedidos, setPedidos] = useState([]);
  const [transportista, setTransportista] = useState({});
  const [firma, setFirma] = useState(null);
  const [redirectMessage, setRedirectMessage] = useState(false);
  const [canProceed, setCanProceed] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setPedidos([]);
    setTransportista({});
    setFirma(null);
    setRedirectMessage(false);
  };

  const handleSetPedidos = (data) => {
    // console.log('🚀 ~ handleSetPedidos ~ newPedidos:', data);
    setPedidos(data);
  };

  const handleSetTransporte = (data) => {
    // console.log('🚀 ~ handleSetTransporte ~ data:', data);
    setTransportista(data);
  };

  const handleSetFirma = (data) => {
    // console.log('🚀 ~ handleSetFirma ~ data:', data);
    setFirma(data);
  };

  useEffect(() => {
    if (activeStep === steps.length) {
      setRedirectMessage(true);
      const timer = setTimeout(() => {
        handleReset();
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [activeStep]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step1 pedidos={pedidos} setPedidos={handleSetPedidos} />;
      case 1:
        return (
          <Step2
            setTransportista={handleSetTransporte}
            setCanProceed={setCanProceed}
          />
        );
      case 2:
        return (
          <Step3 setFirma={handleSetFirma} setCanProceed={setCanProceed} />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className='w-100'>
      <div className='d-flex align-items-center justify-content-center'>
        <div className='col-12 col-md-8 col-lg-6'>
          <div className='w-100'>
            <Stepper
              hidden={redirectMessage}
              activeStep={activeStep}
              alternativeLabel
              sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                      color: "#29a745"
                  },
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                      color: "#212529"
                  },
                  "& .MuiStepLabel-root .Mui-active": {
                      color: "#215732"
                  },
                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                      color: "#212529"
                  },
                  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "#FFF"
                  }
              }}
            >
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          <div className='w-100'>
              {activeStep === steps.length ? (
                <div className='d-flex flex-column align-items-center justify-content-center'>
                  <p>
                    Todos los pasos han sido completados. 
                    <CheckCircleIcon
                      sx={{ fontSize: 60, color: '#215732', mt: 2, mb: 1 }}
                    />
                  </p>

                  {redirectMessage && (
                    <p className='text-center'>
                      Redirigiendo en 10 segundos...
                    </p>
                  )}
                </div>
              ) : (
                <div className='w-100'>
                  <div className='w-100 py-5'>
                    {getStepContent(activeStep)}
                  </div>

                  <div className='w-100 d-flex align-items-center justify-content-end'>
                    <button 
                      type='button' 
                      className='btn btn-default me-3' 
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Atrás
                    </button>
                    
                    <button 
                      type='button' 
                      className='btn btn-primary' 
                      disabled={
                        (activeStep === 0 && pedidos.length === 0) ||
                        (activeStep === 1 && !canProceed) ||
                        (activeStep === 2 && !firma)
                      }
                      onClick={handleNext}
                    >
                     { activeStep === steps.length - 1
                        ? 'Finalizar'
                        : 'Siguiente'
                      }
                    </button>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
