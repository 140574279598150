/* eslint-disable jsx-a11y/alt-text */
import { Print } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { React, useEffect, useState } from 'react';
import ConfirmModal from '../../shared/components/modals/Confirm';
import MassiveActionsModal from '../../shared/components/modals/MassiveActions';
import PdfViewerModal from '../../shared/components/modals/PdfViewer';
import PiezaModal from '../../shared/components/modals/PiezaInfo';
import DashboardService from '../../../../../services/dashboard';
import { getActions } from './actions';
import ActionsMenu from '../../shared/components/ActionsMenu';
import { fetchDashboard } from '../../../../../redux/features/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import CustomDatePicker from '../../shared/components/CustomDatePicker';
import PiezaPreview from '../../shared/components/PiezaPreview';
import InfoModal from '../../shared/components/modals/Info';
import UserSelector from './UserSelector';
import { updatePedido } from '../../shared/services/pedidos';
import { toast } from 'react-toastify';

export default function PedidosTable({ 
  id,
  lastUpdated,
  pedidos,
  userId,
  isAdmin 
}) {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const dashboard = useSelector(state => {
    return state.dashboard.entity
  }); 
  const [currentPage, setPage] = useState(1);
  const [filteredPedidos, setFilteredPedidos] = useState([]);
  const [filterValues, setFilterValues] = useState({
    id: '',
    num_presupuesto: '',
    fecha: '',
  });
  const [modalPiezaOpen, setModalPiezaOpen] = useState(false);
  const [modalUserOpen, setModalUserOpen] = useState(false);
  const [selectedPieza, setSelectedPieza] = useState(null);
  const [selectedPedido, setSelectedPedido] = useState(null);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [massEditMode, setMassEditMode] = useState(false);
  const [selectedPedidos, setSelectedPedidos] = useState([]);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [modalMassiveActionsOpen, setModalMassiveActionsOpen] = useState(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalAction, setConfirmModalAction] = useState(null);
  const [confirmModalPedido, setConfirmModalPedido] = useState(null);

  useEffect(() => {
    if(pedidos) {
      setFilteredPedidos(pedidos);
    }
  }, [lastUpdated, pedidos]);

  useEffect(() => {
    fetchPedidos(currentPage, filterValues);
  }, [filterValues]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchPedidos(newPage);
  };

  const fetchPedidos = (newPage = 1, filters) => {
    if(id === 'general') {
      dispatch(fetchDashboard(newPage, 1, null, filters))
    } else if(id === 'individual') {
      dispatch(fetchDashboard(newPage, 2, currentUser.id, filters))
    } else {
      dispatch(fetchDashboard(newPage, 3, null, filters))
    }
  }

  const getPaginationCount = () => {
    const isFilterEmpty = Object.values(filterValues).every(x => x === null || x === '');
    const total = isFilterEmpty ? (dashboard?.pedidos?.count ? dashboard?.pedidos?.count : 1) : filteredPedidos.length;
    var totalPages = total < 15 ? 1 : Math.ceil(total / 15);
    return parseInt(totalPages);
  }

  const handleOpenModalPieza = (pieza) => {
    setSelectedPieza(pieza);
    setModalPiezaOpen(true);
  };

  const handleOpenAsignarPedido = (pedido) => {
    setSelectedPedido(pedido);
    setModalUserOpen(true);
  };

  const handleOpenModalMassiveActions = () => {
    setModalMassiveActionsOpen(true);
  };

  const handleCloseModalMassiveActions = () => {
    setModalMassiveActionsOpen(false);
  };

  const handlePreviewEtiqueta = (pedido) => {
    setSelectedPedido(pedido);
    setPdfViewerOpen(true);
  };

  const handleClosePdfViewer = () => {
    setSelectedPedido(null);
    setPdfViewerOpen(false);
  };

  const handleCloseModalPieza = () => {
    setModalPiezaOpen(false);
    setSelectedPieza(null);
  };

  const handleCloseModalUser = () => {
    setModalUserOpen(false);
    setSelectedPedido(null);
  };

  const handleConfirmAction = (confirm) => {
    setConfirmModalOpen(false);
    if (confirm && confirmModalAction && confirmModalPedido) {
      executeAction(confirmModalAction, confirmModalPedido);
    }
  };

  const handleImprimirEtiqueta = async(pedido) => {
    const response = await DashboardService.handleImprimirEtiqueta(pedido);
    fetchPedidos(currentPage)
  }

  const handleSolicitarEtiqueta = async(pedido) => {
    const piezasParaProcesar = await DashboardService.handleSolicitarEtiqueta(
      pedido
    );
    if (piezasParaProcesar.length > 0) {
      fetchPedidos(currentPage)
    }
  }

  const executeAction = (action, pedido) => {
    if (action === 'print_label') {
      handleImprimirEtiqueta(pedido);
    } else if (action === 'preview_label') {
      handlePreviewEtiqueta(pedido);
    } else if (action === 'shipment_cancel') {
      handleEliminarPedido(pedido);
    } else if (action === 'order_completed') {
      handlePedidoCompletado(pedido);
    } else if (action === 'assign_order') {
      handleOpenAsignarPedido(pedido);
    } else if (action === 'request_label') {
      handleSolicitarEtiqueta(pedido);
    } else if (action === 'pedido_pendiente') {
      actualizarPedido(pedido, null, 1);
    }
  };

  const handleAction = (action, pedido) => {
    if (!isAdmin) return;
    if (
      ['shipment_cancel', 'order_completed', 'assign_order'].includes(action) &&
      pedido.piezas.length > 1
    ) {
      setConfirmModalAction(action);
      setConfirmModalPedido(pedido);
      setConfirmModalOpen(true);
    } else {
      executeAction(action, pedido);
    }
  };

  const handleEliminarPedido = (pedido) => {
    actualizarPedido(
      pedido,
      userId,
      4 // RECHAZADO
    );
  };

  const handlePedidoCompletado = (pedido) => {
    actualizarPedido(
      pedido,
      userId,
      3 // COMPLETADO
    );
  };

  const handleAsignarUsuario = (userId) => {
    actualizarPedido(
      selectedPedido,
      userId,
      2 // ASIGNADO
    );
    setSelectedPedido(null);
  };

  const actualizarPedido = async (pedido, userId, estadoId) => {
    try {
      dispatch({type: 'loading/set', payload: true});
      const body = {
        user_id: userId,
        estado_id: estadoId
      };
      
      const response = await updatePedido(pedido.id, body);

      if (response.success) {
        toast.success(response.message);
        dispatch({type: 'loading/set', payload: false});
        handleCloseModalUser();
        fetchPedidos(1)
      }
    } catch (error) {
      console.error('Error updating pedido:', error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterValues((prevValues) => ({
      ...prevValues,
      [name]: name === 'fecha' ? 
        ((value === "" || value === null) ? '' : moment(value).format('YYYY-MM-DD')) 
        : value,
    }));
  };

  const clearFilters = () => {
    setFilterValues({
      id: '',
      num_presupuesto: '',
      fecha: '',
    });
  };

  const toggleMassEditMode = () => {
    setMassEditMode(!massEditMode);
    if (massEditMode) {
      setSelectAllChecked(false);
      setSelectedPedidos([]);
    }
  };

  const handleSelectPedido = (pedidoId) => {
    if (selectedPedidos.includes(pedidoId)) {
      setSelectedPedidos(selectedPedidos.filter((id) => id !== pedidoId));
    } else {
      setSelectedPedidos([...selectedPedidos, pedidoId]);
    }
  };

  const handleExecuteMassiveAction = () => {
    if (!isAdmin) return;

    // Open the modal to select the massive action
    handleOpenModalMassiveActions();
  };

  const handleFinalizeMassiveAction = async (options) => {
    console.log('Selected Massive Action:', options);

    if (options.imprimir) {
      const selectedPedidosToPrint = pedidos.filter((pedido) =>
        selectedPedidos.includes(pedido.origin_id)
      );
      await DashboardService.handleImprimirEtiquetaMassiva(
        selectedPedidosToPrint
      );
    }

    if (
      options.marcar.completado ||
      options.marcar.rechazado ||
      options.assignar.habilitado
    ) {
      const estado = options.marcar.completado
        ? 3 // COMPLETADO
        : options.marcar.rechazado
        ? 4 // RECHAZADO
        : 2 // ASIGNADO;

      for (const pedidoId of selectedPedidos) {
        const pedido = pedidos.find((pedido) => pedido.origin_id === pedidoId);
        if (pedido) {
          const idUser = options.assignar.habilitado
            ? options.assignar.user
            : userId;
          await actualizarPedido(pedido, idUser, estado);
        }
      }
      setSelectedPedidos([]);
      setMassEditMode(false);
    }

    setModalMassiveActionsOpen(false);
    setSelectAllChecked(false);
    fetchPedidos(currentPage)
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);

    if(filtersVisible == true) {
      const isFilterEmpty = Object.values(filterValues).every(x => x === null || x === '');

      if(!isFilterEmpty) clearFilters(); // Clear filters when toggling if are filled
    }
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAllChecked(checked);
    if (checked) {
      const allPedidoIds = filteredPedidos.map((pedido) => pedido.origin_id);
      setSelectedPedidos(allPedidoIds);
    } else {
      setSelectedPedidos([]);
    }
  };

  const getStatusPedido = (estadoId) => {
    if(estadoId === 1) return 'text-warning';
    else if(estadoId === 2) return 'text-info';
    else if(estadoId === 4) return 'text-danger';
    else return 'text-green';
  }

  const FilterButtons = () => {
    const renderFilterControls = () => (
      <div className="d-flex">
        <input type='text'
          name='id'
          className='form-control me-2'
          placeholder='Filtrar por ID'
          value={filterValues.id}
          onChange={handleFilterChange}
        />
        <input type='text'
          name='num_presupuesto'
          className='form-control me-2'
          placeholder='Filtrar por Nº Presupuesto'
          value={filterValues.num_presupuesto}
          onChange={handleFilterChange}
        />
        <CustomDatePicker 
          disableFuture={false}
          disablePast={false}
          onChangeDate={(date) => handleFilterChange({target : {name: 'fecha', value: date}})}
        />
        <button className="btn btn-info ms-3" onClick={clearFilters}>
          Limpiar Filtros
        </button>
      </div>
    );

    const renderMassEditButtons = () => (
      <>
        {massEditMode ? (
          <>
            <button
              className="btn btn-primary"
              style={{ backgroundColor: '#CB4335' }}
              onClick={toggleMassEditMode}
              disabled={!isAdmin}
            >
              Cancelar Edición Masiva
            </button>
            <button
              className="btn btn-success ms-3"
              onClick={handleExecuteMassiveAction}
              disabled={!isAdmin}
            >
              Ejecutar Acción Masiva
            </button>
          </>
        ) : (
          <button
            className="btn btn-primary ms-3"
            onClick={toggleMassEditMode}
            hidden={!isAdmin}
          >
            Acción Masiva
          </button>
        )}
      </>
    );

    return (
      <div className="d-flex justify-content-between mb-3 mt-2">
        <div>
          {!massEditMode && (
            <button className="btn btn-primary" onClick={toggleFilters}>
              {filtersVisible ? 'Ocultar Filtros' : 'Filtrar'}
            </button>
          )}
          {!filtersVisible && renderMassEditButtons()}
        </div>
        {filtersVisible && renderFilterControls()}
      </div>
    );
  };

  return (
    <div className="w-100">
      <div className='w-100'>
        {FilterButtons()}

        <div className="w-100 d-flex flex-column align-items-end content-table presupuestos">
          <div className="w-100 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center">
                    {massEditMode && (
                      <div className='d-flex align-items-center'>
                        <Checkbox
                          color='success'
                          checked={selectAllChecked}
                          onChange={handleSelectAll}
                          disabled={!isAdmin}
                          className='p-0'
                        />
                          <b>Todos</b>
                      </div>
                      )}
                  </th>
                  <th>#</th>
                  <th className='text-center'>fecha</th>
                  <th className='text-center'>estado</th>
                  <th>piezas</th>
                  <th className='text-center'>etiqueta</th>
                  <th></th>
                </tr>
              </thead>

                {filteredPedidos?.length === 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan={7} className="text-center">
                        No hay pedidos pendientes
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  filteredPedidos?.map((pedido, index) => {
                    const piezasValues = Object.values(pedido.piezas)
                    const piezasRows = piezasValues.map((pieza, i) => {
                      
                      const massive = i === 0 ?
                        <td rowSpan={piezasValues.length + 1} width={'5%'} className="text-center">
                          {massEditMode ? (
                            <Checkbox
                              color='success'
                              checked={selectedPedidos.includes(pedido.origin_id)}
                              onChange={() => {
                                handleSelectPedido(pedido.origin_id);
                              }}
                              disabled={!isAdmin}
                            />
                            ) : ''
                          }
                        </td>
                      : null;
                      const presupuesto = i === 0 ? <td rowSpan={piezasValues.length + 1} width="8%">
                        {pedido.numero_presupuesto}
                      </td> : null
                      const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
                        {moment(pedido.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                      </td> : null
                      const estado = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className='text-center text-uppercase'>
                        <span className={'fw-bold ' + (getStatusPedido(pedido.estado_id))}>{pedido.estado.nombre}</span>
                      </td> : null
                      const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                        { (isAdmin) ?
                          <ActionsMenu
                            options={getActions(id, userId)}
                            onAction={(action) => handleAction(action, pedido)}
                            disabled={!isAdmin}
                          />
                          :
                          <span style={{ color: 'gray' }}>No Permitido</span>
                        }
                      </td> : null
                            
                      return (
                        <tr key={i}>
                          {massive}
                          {presupuesto}
                          {fecha}
                          {estado}
                          <td>
                            <div className='w-100 d-flex align-items-center'>
                              <div className='flex-grow-1 d-flex flex-column'>
                                <span
                                  onClick={() => handleOpenModalPieza(pieza)}
                                  style={{
                                    cursor: 'pointer',
                                    color: '#215731',
                                  }}
                                >
                                  {pieza.nombre}
                                </span>
                                <span className='fw-bold'>#{pieza.id_referencia_plataforma}</span>
                                <span>REF: {pieza.referencia_pieza}</span>
                              </div>
                              <div className='d-flex justify-content-end'>
                                <span
                                  onClick={() => handleOpenModalPieza(pieza)}
                                  style={{
                                    cursor: 'pointer',
                                    color: '#215731',
                                  }}
                                >
                                  <PiezaPreview
                                    pieza={pieza}
                                    onOpenModal={handleOpenModalPieza}
                                  />
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            {pieza.etiqueta_envio ? (
                              <Print style={{ color: 'green' }} />
                            ) : (
                              <Print style={{ color: '#bd3030' }} />
                            )}
                          </td>
                          {acciones}
                        </tr>
                      )
                    })

                    return (
                      <tbody key={index}>
                        {piezasRows}
                      </tbody>
                    )
                  })
                )
              }
            </table>
          </div>

          <Pagination 
            className="mt-3" 
            count={getPaginationCount()} 
            page={currentPage} 
            onChange={handleChangePage} 
          />
        </div>

        { (modalPiezaOpen) &&
          <PiezaModal
            pieza={selectedPieza}
            state={modalPiezaOpen}
            width="sm"
            onClose={handleCloseModalPieza}
          />
        }

        { (modalUserOpen) &&
          <InfoModal
            title={"Selecciona el usuario al que quieres asignar el pedido"}
            content={
              <UserSelector
                rolId={2}
                onAssign={handleAsignarUsuario}
              />
            }
            state={modalUserOpen}
            width={"sm"}
            onClose={handleCloseModalUser}
          />
        }

        { (pdfViewerOpen) &&
          <PdfViewerModal
            pedido={selectedPedido}
            state={pdfViewerOpen}
            width="md"
            onClose={handleClosePdfViewer}
          />
        }

        { (modalMassiveActionsOpen) &&
          <MassiveActionsModal
            from={1}
            state={modalMassiveActionsOpen}
            width="sm"
            onClose={handleCloseModalMassiveActions}
            onReturn={(options) => handleFinalizeMassiveAction(options)}
          />
        }

        { (confirmModalOpen) &&
          <ConfirmModal
            title="Confirmar acción"
            description="Este pedido tiene más de una pieza, ¿desea continuar con la acción?"
            state={confirmModalOpen}
            onConfirmAction={handleConfirmAction}
            onClose={() => setConfirmModalOpen(false)}
          />
        }
      </div>
    </div>
  )
}
