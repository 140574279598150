import printJS from 'print-js';
import { PDFDocument } from 'pdf-lib';
import JsBarcode from 'jsbarcode';
import { getPdfEtiqueta } from '../../views/Main/components/shared/services/etiquetas';

/**
 * Convierte un ArrayBuffer en una cadena base64.
 *
 * @param {ArrayBuffer} buffer - El ArrayBuffer a convertir.
 * @returns {string} - La cadena base64 resultante.
 */
const arrayBufferToBase64 = (buffer) => {
  const bytes = new Uint8Array(buffer);
  let binary = '';
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

/**
 * Convierte un objeto Blob en una cadena de base64.
 *
 * @param {Blob} blob - El objeto Blob a convertir.
 * @returns {Promise<string>} - Una promesa que se resuelve con la cadena de base64 resultante.
 */
const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result.split(',')[1]);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

/**
 * Limpia una cadena de texto en formato base64 eliminando cualquier carácter de espacio en blanco.
 *
 * @param {string} base64 - La cadena de texto en formato base64 a limpiar.
 * @returns {string} - La cadena de texto en formato base64 sin caracteres de espacio en blanco.
 */
const cleanBase64 = (base64) => {
  if (base64.startsWith('data:')) {
    base64 = base64.split(',')[1];
  }
  return base64.replace(/\s/g, '');
};

/**
 * Agrega un código de barras a un archivo PDF.
 *
 * @param {string} base64Pdf - La cadena base64 del archivo PDF.
 * @param {string} barcodeText - El texto del código de barras.
 * @returns {Promise<string>} - Una promesa que se resuelve con la cadena base64 del archivo PDF modificado.
 */
const addBarcodeToPDF = async (base64Pdf, barcodeText) => {
  // convert base64 to Array Buffer
  const pdfBytes = Uint8Array.from(atob(unescape(base64Pdf)), c => c.charCodeAt(0))
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, barcodeText, {
    format: 'CODE128',
    width: 2,
    height: 50,
  });
  const barcodeImage = canvas.toDataURL('image/png');

  const pdfDoc = await PDFDocument.load(pdfBytes);
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  const A5_WIDTH = 420; // A4 width in points
  const A5_HEIGHT = 595; // A4 height in points

  const { width: originalWidth, height: originalHeight } = firstPage.getSize();

  const newPdfDoc = await PDFDocument.create();
  const newPage = newPdfDoc.addPage([A5_WIDTH, A5_HEIGHT]);

  const x = 0;
  const y = A5_HEIGHT - originalHeight;

  const [embeddedPage] = await newPdfDoc.embedPages([firstPage]);
  newPage.drawPage(embeddedPage, { x, y });

  const barcodeWidth = 100;
  const barcodeHeight = 50;
  const barcodeImageBytes = await fetch(barcodeImage).then((res) =>
    res.arrayBuffer()
  );
  const barcodeImageEmbed = await newPdfDoc.embedPng(barcodeImageBytes);

  const barcodeX = A5_WIDTH - barcodeWidth - 10;
  const barcodeY = 10;

  newPage.drawImage(barcodeImageEmbed, {
    x: barcodeX,
    y: barcodeY,
    width: barcodeWidth,
    height: barcodeHeight,
  });

  const modifiedPdfBytes = await newPdfDoc.save();
  return arrayBufferToBase64(modifiedPdfBytes);
};

/**
 * Obtiene la etiqueta en formato PDF y le agrega un código de barras.
 *
 * @param {string} envio_id - El ID del envío.
 * @param {number} plataforma - La plataforma.
 * @param {string} pedido_id - El ID del pedido.
 * @returns {Promise<string|null>} - La etiqueta en formato PDF en base64 o null si no se pudo obtener.
 */
export const getEtiqueta = async (envio_id, plataforma, pedido_id, num_presupuesto) => {
  if (!envio_id && !pedido_id) {
    return null;
  }

  try {
    let response;
    if (plataforma === 3) {
      response = await getPdfEtiqueta('email', pedido_id);
    } else {
      response = await getPdfEtiqueta('pedido', pedido_id, envio_id);
    }

    if (response.success) {
      const base64Pdf = response.etiqueta;
      return `data:application/pdf;base64,${await addBarcodeToPDF(
        base64Pdf,
        num_presupuesto
      )}`;
    } else return null;
  } catch (error) {
    console.error('Error in getEtiqueta:', error);
    return null;
  }
};

/**
 * Descarga un archivo PDF a partir de una cadena en base64.
 *
 * @param {string} base64 - La cadena en base64 que representa el archivo PDF.
 * @param {string} filename - El nombre del archivo PDF a descargar.
 */
export const downloadPDF = (base64, filename) => {
  const linkSource = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement('a');
  const fileName = filename;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

/**
 * Combina varios archivos PDF en uno solo.
 *
 * @param {string[]} pdfBase64s - Un array de strings que contienen los archivos PDF en formato base64.
 * @returns {Promise<string>} - Una promesa que se resuelve con el archivo PDF combinado en formato base64.
 */
const mergePDFs = async (pdfBase64s) => {
  const mergedPdf = await PDFDocument.create();
  for (const base64 of pdfBase64s) {
    const pdfData = await fetch(
      `data:application/pdf;base64,${cleanBase64(base64)}`
    ).then((res) => res.arrayBuffer());
    const pdf = await PDFDocument.load(pdfData);
    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    copiedPages.forEach((page) => {
      mergedPdf.addPage(page);
    });
  }
  const mergedPdfData = await mergedPdf.saveAsBase64({ dataUri: true });
  return mergedPdfData;
};

/**
 * Imprime un documento PDF fusionado.
 *
 * @param {string[]} pdfBase64s - Un array de cadenas PDF codificadas en base64.
 * @returns {Promise<void>} - Una promesa que se resuelve cuando el PDF se imprime correctamente.
 */
export const printMergedPDF = async (pdfBase64s) => {
  try {
    const mergedPdfBase64 = await mergePDFs(pdfBase64s);
    const cleanedBase64 = cleanBase64(mergedPdfBase64);
    printJS({ printable: cleanedBase64, type: 'pdf', base64: true });
  } catch (error) {
    console.error('Error merging and printing PDF:', error);
  }
};
