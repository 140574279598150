import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination, FreeMode, Zoom } from 'swiper/modules'
import imgDefault from '../../../../../../images/placeholder.jpg'
import './swiperSlider.scss'
import {Skeleton} from '@mui/material';


const SwiperSlider = ({images, setMainImgId, mainImgId, isEdit, isLoadingImgs}) => {
    return (
        <div className="mySwiper">
            <div>
                {isLoadingImgs 
                    ? <div className='mySwiper__skeleton-container'>
                        <div className='mySwiper__skeleton-container--each'>
                            <Skeleton variant="rounded" className='mySwiper__skeleton-container--each--main-skeleton' width={'100%'} />
                            <Skeleton width={'60%'} />
                        </div>
                        <div className='mySwiper__skeleton-container--each'>
                            <Skeleton variant="rounded" className='mySwiper__skeleton-container--each--main-skeleton' width={'100%'} />
                            <Skeleton width={'60%'} />
                        </div>
                        <div className='mySwiper__skeleton-container--each'>
                            <Skeleton variant="rounded" className='mySwiper__skeleton-container--each--main-skeleton' width={'100%'} />
                            <Skeleton width={'60%'} />
                        </div>
                      </div>
                    : (images?.length > 0
                        ? <Swiper
                                style={{
                                    '--swiper-navigation-color': '#215732',
                                    '--swiper-pagination-color': '#215732',
                                }}
                                spaceBetween={10}
                                slidesPerView={3}
                                navigation={true}
                                zoom={true}
                                pagination={true}
                                modules={[FreeMode, Navigation, Zoom, Pagination]}
                                initialSlide={0}
                            >
                                {images?.map(({ruta, idLinea}, idx) => (
                                    <SwiperSlide key={idLinea} >
                                        <div className='mySwiper__img swiper-zoom-container'>
                                            <img src={ruta} alt={'img' + idx} />
                                            <label className='mySwiper__img--ppal'>
                                                IMAGEN PRINCIPAL{' '}
                                                <input 
                                                    onChange={(e) => setMainImgId(e.target.checked ? idLinea : null)} 
                                                    type='checkbox' 
                                                    disabled={!isEdit} 
                                                    checked={idLinea === mainImgId} />
                                            </label>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        : <div className='mySwiper__img default-img'><img src={imgDefault} alt='img-default' className='img-default'/></div>)
                }
            </div>
        </div>
    );
}

export default SwiperSlider