import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecogidas } from "../../../../redux/features/recogida";
import moment from "moment";
import { Print } from "@mui/icons-material";
import PiezaPreview from "../shared/components/PiezaPreview";
import PiezaModal from "../shared/components/modals/PiezaInfo";
import { Pagination } from "@mui/material";

export default function Recogida() {
  const dispatch = useDispatch();
  const recogidas = useSelector(state => {
    return state.recogidas.entity
  }); 
  const [currentPage, setPage] = useState(1);
  const [filteredPedidos, setFilteredPedidos] = useState([]);
  const [filterValues, setFilterValues] = useState({
    id: '',
    num_presupuesto: '',
    fecha: '',
  });
  const [selectedPieza, setSelectedPieza] = useState({});
  const [modalPiezaOpen, setModalPiezaOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchRecogidas(1));
  }, [])

  useEffect(() => {
    if(recogidas?.pedidos?.rows?.length > 0) {
      setFilteredPedidos(recogidas.pedidos?.rows)
    }
  }, [recogidas])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(fetchRecogidas(newPage));
  };

  const getPaginationCount = () => {
    const isFilterEmpty = Object.values(filterValues).every(x => x === null || x === '');
    const total = isFilterEmpty ? (recogidas?.pedidos?.count ? recogidas?.pedidos?.count : 1) : filteredPedidos.length;
    var totalPages = total < 15 ? 1 : Math.ceil(total / 15);
    return parseInt(totalPages);
  }

  const handleOpenModalPieza = (pieza) => {
    setSelectedPieza(pieza);
    setModalPiezaOpen(true);
  };

  const handleCloseModalPieza = () => {
    setModalPiezaOpen(false);
    setSelectedPieza({});
  };

  return (
    <div className="content">
      <h1>Recogidas</h1>

      <div className='w-100'>
        <div className="w-100 d-flex flex-column align-items-end content-table presupuestos">
          <div className="w-100 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th className='text-center'>fecha</th>
                  <th className='text-center'>estado</th>
                  <th>piezas</th>
                  <th className='text-center'>etiqueta</th>
                </tr>
              </thead>

                {filteredPedidos?.length === 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan={7} className="text-center">
                        No hay pedidos completados
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  filteredPedidos?.map((pedido, index) => {
                    const piezasValues = Object.values(pedido.piezas)
                    const piezasRows = piezasValues.map((pieza, i) => {
                      
                      const presupuesto = i === 0 ? <td rowSpan={piezasValues.length + 1} width="8%">
                        {pedido.numero_presupuesto}
                      </td> : null
                      const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
                        {moment(pedido.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                      </td> : null
                      const estado = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className='text-center text-uppercase'>
                        <span className={'fw-bold ' + (pedido?.estado_id === 3 ? 'text-green' : '')}>{pedido.estado.nombre}</span>
                      </td> : null
                      
                            
                      return (
                        <tr key={i}>
                          {presupuesto}
                          {fecha}
                          {estado}
                          <td>
                            <div className='w-100 d-flex align-items-center'>
                              <div className='flex-grow-1 d-flex flex-column'>
                                <span
                                  onClick={() => handleOpenModalPieza(pieza)}
                                  style={{
                                    cursor: 'pointer',
                                    color: '#215731',
                                  }}
                                >
                                  {pieza.nombre}
                                </span>
                                <span className='fw-bold'>#{pieza.id_referencia_plataforma}</span>
                                <span>REF: {pieza.referencia_pieza}</span>
                              </div>
                              <div className='d-flex justify-content-end'>
                                <span
                                  onClick={() => handleOpenModalPieza(pieza)}
                                  style={{
                                    cursor: 'pointer',
                                    color: '#215731',
                                  }}
                                >
                                  <PiezaPreview
                                    pieza={pieza}
                                    onOpenModal={handleOpenModalPieza}
                                  />
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            {pieza.etiqueta_envio ? (
                              <Print style={{ color: 'green' }} />
                            ) : (
                              <Print style={{ color: '#bd3030' }} />
                            )}
                          </td>
                        </tr>
                      )
                    })

                    return (
                      <tbody key={index}>
                        {piezasRows}
                      </tbody>
                    )
                  })
                )
              }
            </table>
          </div>

          <Pagination 
            className="mt-3" 
            count={getPaginationCount()} 
            page={currentPage} 
            onChange={handleChangePage} 
          />
        </div>

        <PiezaModal
          pieza={selectedPieza}
          state={modalPiezaOpen}
          width="sm"
          onClose={handleCloseModalPieza}
        />
      </div>
    </div>
  );
}