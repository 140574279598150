import API from '../../../../../services/api';

export const getPedidos = async (page, status, userId, filters) => {
    const query = '?page=' + page + '&status=' + status + (userId ? '&user_id=' + userId : '')
    let filtersQuery = '';

    if(filters) {
        if(filters.id !== null && filters.id !== '') filtersQuery += '&id_referencia_plataforma=' + filters.id;
        if(filters.num_presupuesto !== null && filters.num_presupuesto !== '')  filtersQuery += '&presupuesto=' + filters.num_presupuesto;
        if(filters.fecha !== null && filters.fecha !== '') filtersQuery += '&fecha=' + filters.fecha;
    }

    return await API.get('/pedidos'+ query + filtersQuery).then(res => res.data);
}

export const getPedidosCompletados = async () => {
    return await API.get('/pedidos/completados').then((res) => res.data);
}

export const getPedidoById = async (id) => {
    return await API.get(`/pedidos/${id}`).then((res) => res.data);
}

export const updatePedido = async (id, pedido) => {
    return await API.put(`/pedidos/${id}`, pedido).then((res) => res.data);
}

