import React, { useEffect, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import Loading from '../../Loading';
import { API_USERS } from '../../../../../../../api/services/User';

const DASHBOARD_ORIGIN = {
  GENERAL: 1,
  USER: 2,
};

const BASIC_STATE = {
  imprimir: false,
  marcar: { completado: false, rechazado: false, pendiente: false },
  assignar: { habilitado: false, user: '' },
};

export default function MassiveActionsModal({
  from,
  state,
  width,
  onClose,
  onReturn,
}) {
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [opcionesMassivas, setOpcionesMassivas] = useState(BASIC_STATE);

  const getUsuarios = async () => {
    try {
      const res = await API_USERS.getByTypeId(2);
      if (res.success) {
        setUsuarios(res.usuarios);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching the users:', error);
    }
  };

  useEffect(() => {
    if (state) {
      setLoading(true);
      if (from === DASHBOARD_ORIGIN.GENERAL) {
        getUsuarios();
      }
    }
    setOpcionesMassivas(BASIC_STATE);
    setLoading(false);
  }, [state]);

  const handleOptionChange = (event) => {
    const value = event.target.value;
    if (value === 'imprimir') {
      setOpcionesMassivas({
        imprimir: true,
        marcar: { completado: false, rechazado: false, pendiente: false },
        assignar: { habilitado: false, user: '' },
      });
    } else if (
      value === 'completado' ||
      value === 'rechazado' ||
      value === 'pendiente'
    ) {
      setOpcionesMassivas({
        imprimir: false,
        marcar: {
          completado: value === 'completado',
          rechazado: value === 'rechazado',
          pendiente: value === 'pendiente',
        },
        assignar: { habilitado: false, user: '' },
      });
    } else if (value === 'asignar') {
      setOpcionesMassivas((prevState) => ({
        ...prevState,
        imprimir: false,
        marcar: { completado: false, rechazado: false, pendiente: false },
        assignar: { habilitado: true, user: '' },
      }));
    }
  };

  const handleUserChange = (event) => {
    const userId = event.target.value;
    setOpcionesMassivas((prevState) => ({
      ...prevState,
      assignar: { ...prevState.assignar, user: userId },
    }));
  };

  return (
    <Dialog
      open={state}
      onClose={onClose}
      fullWidth={true}
      maxWidth={width ? width : 'sm'}
      aria-labelledby="info-title"
      aria-describedby="info-description"
    >
      <DialogTitle id="info-title">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <p className="text-uppercase fw-bold">Selector de Acciones Masivas</p>
          <span title="cerrar" className="close" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} size="1x" color="#215732" />
          </span>
        </div>
        <div>
          <p>
            Selecciona la acción que deseas realizar de forma masiva en los
            pedidos. Las acciones disponibles son:
          </p>
        </div>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div>
              <h5>¿Qué acción deseas realizar?</h5>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel id="accion-masiva-label">Acción</InputLabel>
                <Select
                  labelId="accion-masiva-label"
                  value={
                    opcionesMassivas.imprimir
                      ? 'imprimir'
                      : opcionesMassivas.marcar.completado
                      ? 'completado'
                      : opcionesMassivas.marcar.rechazado
                      ? 'rechazado'
                      : opcionesMassivas.marcar.pendiente
                      ? 'pendiente'
                      : opcionesMassivas.assignar.habilitado
                      ? 'asignar'
                      : ''
                  }
                  onChange={handleOptionChange}
                  label="Acción"
                >
                  <MenuItem value="imprimir">Imprimir</MenuItem>
                  <MenuItem value="completado">
                    Marcar Pedido Completado
                  </MenuItem>
                  <MenuItem value="rechazado">Marcar Pedido Rechazado</MenuItem>
                  {from === DASHBOARD_ORIGIN.GENERAL ? (
                    <MenuItem value="asignar">Asignar</MenuItem>
                  ) : (
                    <MenuItem value="pendiente">
                      Marcar Pedido Pendiente
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              {opcionesMassivas.assignar.habilitado && (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel id="usuario-label">
                    Seleccionar Usuario
                  </InputLabel>
                  <Select
                    labelId="usuario-label"
                    value={opcionesMassivas.assignar.user || ''}
                    onChange={handleUserChange}
                    label="Seleccionar Usuario"
                  >
                    {usuarios.map((usuario) => (
                      <MenuItem key={usuario.id} value={usuario.id}>
                        {usuario.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary"
                onClick={() => {
                  onReturn(opcionesMassivas);
                  onClose();
                }}
              >
                Ejecutar
              </button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
