import { faArrowDownWideShort, faArrowUpWideShort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { useState } from 'react';

const ColumnHeader = ({ 
  title, 
  name, 
  filterBy, 
  onFilterBy, 
  onOrderBy
}) => {
  const textCenterCols = ['ref1', 'ref2', 'ref3', 'estado', 'precio', 'ubica'];
  const [isInputVisible, setIsInputVisible] = useState(false)

  const handleShowInput = () => {
    setIsInputVisible(true);
  }

  return (
    <th 
      key={'column-' + title}
      className={textCenterCols.find(txt => txt === name) ? 'text-center' : ''}>
      
      {isInputVisible ? 
        <div className='vin-input-search'>
          <input 
            type='text' 
            autoFocus
            id={name}
            placeholder={`Filtra por ${title}`}
            onKeyUp={(e) => {
              if (e.key === 'Escape') setIsInputVisible(false)
            }}
            onBlur={() => {
              setIsInputVisible(false)}} 
            onChange={(e) => onFilterBy(name, e.target.value)} value={filterBy.value}/>
        </div>
        : 
        <div>
          <Tooltip className='vin-input-search-tooltip' title={`Doble click para buscar por ${title}`}>
            <span onDoubleClick={(e) => handleShowInput()}>{title}</span>
          </Tooltip>
          
          <span className='ms-2'>
            {title && 
              <FontAwesomeIcon 
                onClick={() => onOrderBy(name)} 
                icon={(filterBy.name === name && filterBy.value === 'asc' )? faArrowUpWideShort  : faArrowDownWideShort}
              />
            }
          </span>
        </div>
      }
    </th>
  );
}

export default ColumnHeader
