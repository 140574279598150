



export const formatUrlImage = (CDNservices, image) => {
    const cdn_img = CDNservices.find(
      (cdn) => cdn.id === image.cdn_service_id
    );
    const url_img = cdn_img.base_path + image.imagen_url;
    return url_img;
};