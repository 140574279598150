import { toast } from "react-toastify";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { getPedidos } from "../../views/Main/components/shared/services/pedidos";

const initialRecogidas = { };

export const recogidaReducer = (state = initialRecogidas, action) => {
    switch(action.type){
        case "recogidas/fullfilled": {
            return action.payload;
        }
        default:
            return state
    }
}

const asyncRecogidas = makeAsyncTypes('recogidas');
const [setPending, setFullFilled, setError ] = asyncMac(asyncRecogidas);

export const recogidaFetchingReducer = makeFetchingReducer([
    'recogidas/pending', 
    'recogidas/fullfilled', 
    'recogidas/rejected'
]);

export const fetchRecogidas = (page, filters = null) => async dispatch => {
    dispatch({type: 'loading/set', payload: true});
    dispatch(setPending());

    try {
        const response = await getPedidos(page, 3, null, filters);
        const data = await response.data;
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const recogidaStatusReducer = recogidaFetchingReducer