import config from '../../config';

export const fetchPedidos = async (host, createdAt, token) => {
  const url = `${host}almacen/ventas?limit_date=${createdAt}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: generateHeaders(token),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchTransportistas = async () => {
  const host = config.recomotor.voApiUrl;
  const url = `${host}almacen/transportistas`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: generateHeaders(config.recomotor.externalToken),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const sendNotifcationOficina = async (host, token, body) => {
  const url = `${host}almacen/notificacion/etiqueta-envio`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: generateHeaders(token),
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

const generateHeaders = (token) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};
