import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function UserSelector({
    rolId,
    onAssign,
}) {
    const [usuarios, setUsuarios] = useState([]);
    const [checked, setChecked] = useState(-1);
    const users = useSelector(state => {
        return state.users
    });

    useEffect(() => {
        if(rolId) {
            const newList = users.filter(u => u.rol_id === rolId);

            setUsuarios(newList);
        } else setUsuarios(users);
    }, [rolId]);

    const handleToggle = (value) => () => {
        if (checked === value) {
            setChecked(-1);
        } else {
            setChecked(value);
        }
    };

  return (
    <div>
        {usuarios?.length === 0 ? (
            <div className="text-center">
                <p>No hay usuarios para mostrar</p>
            </div>
        ) : (
            <List>
            { usuarios.map((user, index) => {
                const value = user.id;
                return (
                <ListItem key={value} disablePadding>
                    <ListItemButton
                        role={undefined}
                        onClick={handleToggle(value)}
                        dense
                    >
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={checked === value}
                                tabIndex={-1}
                                color='success'
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText
                            id={index}
                            primary={`${user.username}`}
                        />
                    </ListItemButton>
                </ListItem>
                );
            })}
            </List>
        )}
        <div className="d-flex justify-content-end">
            <button
                className="btn btn-primary"
                disabled={checked === -1 || checked === null}
                onClick={() => { onAssign(checked); }}>
                Asignar
            </button>
        </div>
    </div>
  );
}
