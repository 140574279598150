import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchTransportistas } from '../../../../../services/api/index';

export default function Step2({ setTransportista, setCanProceed }) {
  const [transportistas, setTransportistas] = useState([]);
  const [serviciosDict, setServiciosDict] = useState({});
  const [selectedTransportista, setSelectedTransportista] = useState('');
  const [selectedServicio, setSelectedServicio] = useState('');
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const res = await fetchTransportistas();
        if (res.success === true) {
          const transportistas = res.data.map((transportista) => ({
            id: transportista.id,
            label: transportista.nombre,
          }));

          const servicios = res.data.reduce((acc, transportista) => {
            acc[transportista.id] = transportista.servicios.map((servicio) => ({
              id: servicio.id,
              value: servicio.nombre,
            }));
            return acc;
          }, {});

          setTransportistas(transportistas);
          setServiciosDict(servicios);
        } else {
          throw new Error('Failed to fetch options');
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    if (
      selectedTransportista &&
      serviciosDict[selectedTransportista].length === 1
    ) {
      setSelectedServicio(serviciosDict[selectedTransportista][0].id);
    } else {
      setSelectedServicio('');
    }
  }, [selectedTransportista, serviciosDict]);

  useEffect(() => {
    setTransportista({ selectedTransportista, selectedServicio, textValue });
    const canProceed =
      selectedTransportista &&
      textValue.trim() &&
      (serviciosDict[selectedTransportista].length === 0 || selectedServicio);
    setCanProceed(canProceed);
  }, [
    selectedTransportista,
    selectedServicio,
    textValue,
    setTransportista,
    serviciosDict,
    setCanProceed,
  ]);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" align="center" gutterBottom>
        Datos Adicionales
      </Typography>
      {transportistas.length === 0 ? (
        <Typography variant="body1" align="center">
          Cargando opciones...
        </Typography>
      ) : (
        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
          <InputLabel id="select-transportista-label">
            Seleccione empresa transporte
          </InputLabel>
          <Select
            labelId="select-transportista-label"
            id="select-transportista"
            value={selectedTransportista}
            label="Transportista"
            onChange={(e) => {
              setSelectedTransportista(e.target.value);
            }}
          >
            {transportistas.map((option, index) => (
              <MenuItem key={index} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {selectedTransportista &&
        serviciosDict[selectedTransportista].length > 0 && (
          <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
            <InputLabel id="select-servicio-label">
              Seleccione servicio
            </InputLabel>
            <Select
              labelId="select-servicio-label"
              id="select-servicio"
              value={selectedServicio}
              label="Servicio"
              onChange={(e) => setSelectedServicio(e.target.value)}
            >
              {serviciosDict[selectedTransportista].map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      <TextField
        fullWidth
        variant="outlined"
        label="Nombre del Empleado"
        placeholder="JOHN DOE"
        value={textValue}
        onChange={(e) => setTextValue(e.target.value.toUpperCase())}
        sx={{ mt: 2, mb: 2 }}
      />
    </Box>
  );
}
