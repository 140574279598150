import API from '../../../../../services/api';

export const searchPartsByVin = async (param) => {
    return await API.post('/vin/parts', param).then(res => res.data);
}

export const getImagesByPartId = async (partId) => {
    return await API.post('/vin/images', {partId}).then(res => res.data)
}

export const getImagesByPartIdDesm = async (partId) => {
    return await API.post('/vin/imagesdesmontadas', {partId}).then(res => res.data)
}

export const editPart = async (data) => {
    return await API.post('/vin/editpart', data).then(res => res.data)
}

export const getProductDetail = async (data) => {
    return await API.post('/vin/productdetail', data).then(res => res.data)
}

export const getImagesByReferencia = async (partId) => {
    return await API.post('/vin/imagesdesmontadas/all', {partId}).then(res => res.data)
}

