import { useEffect } from 'react';
import ColumnHeader from './ColumnHeader';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import '../vin.scss';
import { statesData, vinTableData } from '../data';
import { Tooltip } from '@mui/material';
import ActionsMenu from '../../shared/components/ActionsMenu';
import { getActions } from './actions';

const VinTable = ({
    data,
    filterBy,
    onOrderBy,
    onFilterBy,
    onReset,
    onEdit,
    onViewMoreInfo
}) => {

    useEffect(() => {
        
    }, [data, filterBy]);

    const getEstadoName = (estado) => {
        const { nom } = statesData.find(({estpie_cod}) => estpie_cod === estado)
        return nom
    }

    const handleAction = (action, item) => {
        if(action === 'edit') {
            onEdit(item)
        } else if(action === 'info') {
            onViewMoreInfo(item)
        }
    }

    return (
        <div className="w-100">
            <div className="w-100 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            {vinTableData.map(({ value, title }) => (
                                <ColumnHeader
                                    key={value}
                                    title={title}
                                    name={value}
                                    filterBy={filterBy}
                                    onFilterBy={onFilterBy}
                                    onOrderBy={onOrderBy}
                                />
                            ))}
                            <th className="text-center">
                                {filterBy.name === '' ? (
                                    ''
                                ) : (
                                    <Tooltip title="Limpiar filtros">
                                        <RestartAltIcon onClick={onReset} />
                                    </Tooltip>
                                )}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                    {data.length > 0 ? (
                        data.map((item) => (
                        <tr key={'part-' + item.id}>
                            <td width={'5%'} className='fw-bold'>{item.id}</td>
                            <td width={'15%'} title={item.Articulo}>{item.Articulo}</td>
                            <td width={'10%'} className='text-center'>{item.ref1}</td>
                            <td width={'10%'} className='text-center'>{item.ref2}</td>
                            <td width={'10%'} className='text-center'>{item.ref3}</td>
                            <td width={'10%'} className='text-center fw-bold text-uppercase' title={getEstadoName(item.estado)}>
                                {getEstadoName(item.estado)}
                            </td>
                            <td width={'10%'} className='text-center'>{item.precio}€</td>
                            <td width={'10%'} className='text-center' title={item.ubica}>{item.ubica}</td>
                            <td title={item.obspub}>{item.obspub}</td>
                            <td title={item.obspri}>{item.obspri}</td>
                            <td width={'5%'}>
                                <ActionsMenu
                                    options={getActions()}
                                    onAction={(action) => handleAction(action, item)}
                                />
                            </td>
                        </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="11">No hay resultados disponibles</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default VinTable;
