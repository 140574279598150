import { useState } from "react";

export default function useToken() {
  const [token, setToken] = useState(getToken());
  const [userRol, setUserRol] = useState(getUser())

  function getToken() {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    return currentUser?.token;
  }

  function getUser() {
    const currentUser = JSON.parse(localStorage.getItem("user"))
    return currentUser?.rol_id
  }

  function saveToken(user) {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      setToken(user.token);
      setUserRol(user.rol_id);
    } else {
      localStorage.removeItem("user");
      setToken(null);
      setUserRol(null)
    }
  }

  return {
    setToken: saveToken,
    token,
    userRol
  };
}
