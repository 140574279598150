export const getActions = (type, userId = null) => {
  if(type === 'historico') {
    return [
      {
        label: 'Recuperar pedido',
        icon: 'restore',
        action: 'pedido_pendiente',
      }
    ];
  }

  const actions = [
    {
      label: 'Imprimir Etiqueta',
      icon: 'print',
      action: 'print_label',
    },
    {
      label: 'Previsualizar Etiqueta',
      icon: 'preview',
      action: 'preview_label',
    },
    {
      label: 'Eliminar Pedido',
      icon: 'shipment_cancel',
      action: 'shipment_cancel',
    },
    {
      label: 'Marcar Pedido como Completado',
      icon: 'order',
      action: 'order_completed',
    },
    {
      label: 'Solicitar etiqueta a la oficina',
      icon: 'label_request',
      action: 'request_label',
    },
  ];

  if(!userId) {
    actions.splice(2, 0,  {
      label: 'Asignar pedido a',
      icon: 'assign',
      action: 'assign_order',
    });
  }

  return actions;
};
