
export const vinTableData = [
  {
    title: 'Id',
    value: 'id',
    xs: 12,
    sm: 6,
    md: 4,
    edit: false
  },
  {
    title: 'Pieza',
    value: 'Articulo',
    xs: 12,
    sm: 6,
    md: 4,
    edit: false
  },
  {
    title: 'Referencia',
    value: 'ref1',
    xs: 12,
    sm: 6,
    md: 4,
    edit: true
  },
  {
    title: 'Ref 2',
    value: 'ref2',
    xs: 12,
    sm: 6,
    md: 4,
    edit: true
  },
  {
    title: 'Ref 3',
    value: 'ref3',
    xs: 12,
    sm: 6,
    md: 4,
    edit: true
  },
  {
    title: 'Estado',
    value: 'estado',
    xs: 12,
    sm: 6,
    md: 4,
    edit: true
  },
  {
    title: 'Precio',
    value: 'precio',
    xs: 12,
    sm: 6,
    md: 4,
    edit: true
  },
  {
    title: 'Ubicación',
    value: 'ubica',
    xs: 12,
    sm: 6,
    md: 4,
    edit: false
  },
  {
    title: 'Obs. Púb.',
    value: 'obspub',
    xs: 12,
    sm: 12,
    md: 12,
    edit: true
  },
  {
    title: 'Obs. Priv.',
    value: 'obspri',
    xs: 12,
    sm: 12,
    md: 12,
    edit: true
  }
]

export const statesData = [
  {
    estpie_cod: 1,
    nom: "almacenada"
  },
  {
    estpie_cod: 13,
    nom: "desmontada"
  },
  {
    estpie_cod: 2,
    nom: "con incidencia"
  },
  {
    estpie_cod: 3,
    nom: "desechada"
  },
  {
    estpie_cod: 4,
    nom: "en control de calidad"
  },
  {
    estpie_cod: 5,
    nom: "en mostrador"
  },
  {
    estpie_cod: 6,
    nom: "en proceso de desmontaje"
  },
  {
    estpie_cod: 7,
    nom: "montada en vehículo"
  },
  {
    estpie_cod: 8,
    nom: "montada revisada"
  },
  {
    estpie_cod: 9,
    nom: "vendida"
  },
  {
    estpie_cod: 10,
    nom: "situación desconocida"
  },
  {
    estpie_cod: 11,
    nom: "reservada"
  },
  {
    estpie_cod: 12,
    nom: "desubicada"
  }
]

