import API from '../index';

export const API_PIEZAS = {
  notificado: async (id) => {
    return await API.put(`/pieza/${id}/notifica`).then((res) => res.data);
  },
  fetchReferencia: async (referencia) => {
    return await API.get(`/pieza/referencia/${referencia}`).then(
      (res) => res.data
    );
  },
  procesarPiezasAceptadas: async (data) => {
    return await API.post(`/pieza/procesar`, data).then((res) => res.data);
  },
};
