// src/services/dashboardService.js

import { toast } from 'react-toastify';
import { API_PIEZAS } from '../../api/services/Pieza';
import { sendNotifcationOficina } from '../api/index';
import { getEtiqueta, printMergedPDF } from '../pdf';
import config from '../../config';

const host_crm = config.recomotor.crmApiUrl;
const token_external = config.recomotor.externalToken;

const handleEtiquetaOficina = async (pieza, body) => {
  const name_pieza = pieza.nombre;
  try {
    const resultado_notificacion = await sendNotifcationOficina(
      host_crm,
      token_external,
      body
    );
    if (resultado_notificacion.success) {
      toast.success(
        `Se ha notificado a la oficina la etiqueta de ${name_pieza}`
      );
      const res = await API_PIEZAS.notificado(pieza.id);
      if (!res.success) {
        throw Error('Failed to update the piece status');
      }
    } else {
      toast.warn(`Error al notificar la etiqueta de ${name_pieza}`);
    }
  } catch (error) {
    console.error(error);
    toast.warn(`Error al notificar la etiqueta de ${name_pieza}`);
  }
};

const handleImprimirEtiquetaMassiva = async (pedidos) => {
  let allEtiquetas = [];
  for (const pedido of pedidos) {
    let etiquetas = [];
    const num_presupuesto = pedido?.numero_presupuesto;
    const created_by = pedido?.created_by;
    const plataforma = pedido?.plataforma_id;
    const pedido_id = pedido?.id;
    for (let pieza of pedido.piezas) {
      const { id, origin_id, envio_id, notificado } = pieza;
      if (envio_id !== null) {
        try {
          const pdf = await getEtiqueta(envio_id, plataforma, pedido_id, num_presupuesto);
          if (pdf !== null) {
            etiquetas.push({
              pieza_id: id,
              pieza_origin_id: origin_id,
              pdf: pdf,
            });
            if (!notificado) {
              const res = await API_PIEZAS.notificado(pieza.id);
              if (!res.success) {
                throw Error('Failed to update the piece status');
              }
            }
          } else {
            if (pedido.notificado) {
              const body = {
                id_envio: pieza.envio_id,
                num_presupuesto: num_presupuesto,
                created_by: created_by,
              };
              await handleEtiquetaOficina(pieza, body);
            }
            toast.warn(
              `No se ha podido obtener la etiqueta, ya ha sido notificado a la oficina.`
            );
          }
        } catch (error) {
          console.error('Error fetching the label:', error);
          continue;
        }
      }
    }
    allEtiquetas = allEtiquetas.concat(etiquetas);
  }

  if (allEtiquetas.length > 0) {
    const pdfBase64s = allEtiquetas.map((tiquet) => tiquet.pdf);
    printMergedPDF(pdfBase64s);
  }
};

const handleImprimirEtiqueta = async (pedido) => {
  let etiquetas = [];
  const num_presupuesto = pedido?.numero_presupuesto;
  const created_by = pedido?.created_by;
  const plataforma = pedido?.plataforma_id;
  const pedido_id = pedido?.id;

  for (let pieza of pedido.piezas) {
    const { id, origin_id, envio_id, notificado } = pieza;
    if (envio_id !== null) {
      try {
        const pdf = await getEtiqueta(envio_id, plataforma, pedido_id, num_presupuesto);
        if (pdf !== null) {
          etiquetas.push({
            pieza_id: id,
            pieza_origin_id: origin_id,
            pdf: pdf,
          });
          if (!notificado) {
            const res = await API_PIEZAS.notificado(pieza.id);
            if (!res.success) {
              throw Error('Failed to update the piece status');
            }
          }
        } else {
          if (pedido.notificado) {
            const body = {
              id_envio: pieza.envio_id,
              num_presupuesto: num_presupuesto,
              created_by: created_by,
            };
            await handleEtiquetaOficina(pieza, body);
          }
          toast.warn(
            `No se ha podido obtener la etiqueta, ya ha sido notificado a la oficina.`
          );
        }
      } catch (error) {
        console.error('Error fetching the label:', error);
        continue;
      }
    }
  }
  if (etiquetas.length > 0) {
    const pdfBase64s = etiquetas.map((tiquet) => tiquet.pdf);
    printMergedPDF(pdfBase64s);
  }
};

const handleSolicitarEtiqueta = async (pedido) => {
  const num_presupuesto = pedido?.presupuesto?.numero;
  const created_by = pedido?.presupuesto?.created_by;
  const plataforma = pedido?.plataforma_id;
  const pedido_id = pedido?.id;
  const piezasParaProcesar = pedido.piezas.filter(
    (pieza) => !pieza.notificado && pieza.envio_id !== null
  );

  if (piezasParaProcesar.length === 0) {
    toast.info('No hay piezas que notificar o ya han sido notificadas.');
    return [];
  }

  // Si hay piezas para procesar, procede con las solicitudes.
  const requests = piezasParaProcesar.map(async (pieza) => {
    try {
      const etiqueta = await getEtiqueta(pieza?.envio_id, plataforma, pedido_id, num_presupuesto);
      if (etiqueta === null) {
        const body = {
          id_envio: pieza?.envio_id,
          num_presupuesto,
          created_by,
        };
        await handleEtiquetaOficina(pieza, body);
      } else {
        await API_PIEZAS.notificado(pieza.id);
      }
    } catch (error) {
      console.error(`Error al solicitar la etiqueta: ${pieza?.envio_id}`, error);
    }
  });

  await Promise.all(requests);

  if (piezasParaProcesar.length > 0) {
    toast.success(
      'Las piezas sin etiqueta han sido notificadas correctamente.'
    );
  }

  return piezasParaProcesar;
};

const getReferenciasPiezas = (piezas) => {
  const ids_plataforma = piezas.map((pieza) => pieza.id_referencia_plataforma);
  return ids_plataforma.length > 1
    ? ids_plataforma.join(', ')
    : ids_plataforma[0];
};



const DashboardService = {
  handleEtiquetaOficina,
  handleImprimirEtiqueta,
  handleSolicitarEtiqueta,
  getReferenciasPiezas,
  handleImprimirEtiquetaMassiva
};

export default DashboardService;
