import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getEtiqueta } from '../../../../../../../services/pdf';
import { truncateString } from '../../../../../../../utils/text';
import { toast } from 'react-toastify';

export default function PdfViewerModal({ pedido, state, onClose, width }) {
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [iframeLoading, setIframeLoading] = useState([]);

  useEffect(() => {
    if (pedido && pedido.piezas && pedido.piezas.length > 0) {
      setLoading(true);
      setPdfFiles(new Array(pedido.piezas.length).fill(null));
      setIframeLoading(new Array(pedido.piezas.length).fill(true));
      pedido.piezas.forEach(async (pieza, index) => {
        if (pieza.envio_id) {
          await handleEtiqueta(pieza.envio_id, index);
        } else {
          setPdfFiles((prevFiles) => [
            ...prevFiles.slice(0, index),
            null,
            ...prevFiles.slice(index + 1),
          ]);
          setIframeLoading((prevLoading) => [
            ...prevLoading.slice(0, index),
            false,
            ...prevLoading.slice(index + 1),
          ]);
        }
      });
      setLoading(false);
    } else toast.warning('No hay piezas!');
  }, [pedido]);

  const handleEtiqueta = async (envioId, index) => {
    try {
      if (!envioId) {
        throw new Error('Envio ID is null or empty');
      }
      const pdf = await getEtiqueta(
        envioId,
        pedido?.plataforma_id,
        pedido?.id,
        pedido?.numero_presupuesto
      );
      setPdfFiles((prevFiles) => [
        ...prevFiles.slice(0, index),
        pdf,
        ...prevFiles.slice(index + 1),
      ]);
    } catch (error) {
      setPdfFiles((prevFiles) => [
        ...prevFiles.slice(0, index),
        null,
        ...prevFiles.slice(index + 1),
      ]);
    } finally {
      setIframeLoading((prevLoading) => [
        ...prevLoading.slice(0, index),
        false,
        ...prevLoading.slice(index + 1),
      ]);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTabPanel = (pdf, index) => (
    <div
      key={`tabpanel-${index}`}
      hidden={selectedTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ position: 'relative', minHeight: '600px', height: '100%'}}
    >
      {iframeLoading[index] ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <div className='d-flex flex-column align-items-center'>
            <FontAwesomeIcon
              style={{ color: '#215732' }}
              icon={faSpinner}
              size="2x"
              spin
            />
            <h1 className='loading-message'>Cargando...</h1>
          </div>
        </div>
      ) : pdf ? (
        <iframe
          key={`frame-${index}`}
          title={`PDF Viewer ${index}`}
          width="100%"
          height="600"
          allowFullScreen=""
          src={pdf}
          onLoad={() =>
            setIframeLoading((prevLoading) => [
              ...prevLoading.slice(0, index),
              false,
              ...prevLoading.slice(index + 1),
            ])
          }
        ></iframe>
      ) : (
        <div key={`no-doc-${index}`} className="text-center p-3">
          <p>No se ha encontrado documento para esta pieza.</p>
        </div>
      )}
    </div>
  );

  return (
    <Dialog
      open={state}
      onClose={onClose}
      fullWidth={true}
      maxWidth={width || 'lg'}
      aria-labelledby="info-title"
    >
      <DialogTitle id="info-title">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <p className="text-uppercase fw-bold">Visor de PDFs</p>
          <span title="cerrar" className="close" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} size="1x" color="#215732" />
          </span>
        </div>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
          </div>
        ) : (
          <div>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="PDF tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              {pedido?.piezas?.map((pieza, index) => (
                <Tab
                  key={`tab-${index}`}
                  label={truncateString(pieza.nombre, 20)}
                  id={`tab-${index}`}
                  aria-controls={`tabpanel-${index}`}
                />
              ))}
            </Tabs>
            {pdfFiles.map((pdf, index) => renderTabPanel(pdf, index))}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
