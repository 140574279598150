import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Search,
  CheckCircleOutline,
  Cancel,
  ErrorOutline,
} from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { API_PIEZAS } from '../../../../../../../api/services/Pieza';
import { toast } from 'react-toastify';
import ConfirmModal from '../Confirm';

export default function LectorModal({ state, width, onClose }) {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const [codigoPieza, setCodigoPieza] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [piezas, setPiezas] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputCodigo = useRef(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState(null);

  const handleEnterInput = async (e) => {
    if (e.key === 'Enter') {
      setIsLoading(true);
      showErrorMessage('');
      if (
        piezas.find(
          (pieza) => pieza.pieza.id_referencia_plataforma === codigoPieza
        )
      ) {
        showErrorMessage(`La pieza: ${codigoPieza} ya está en la lista.`);
        setIsLoading(false);
        return;
      }
      const pieza = await fetchPieza();
      if (pieza !== null) {
        const pieza_to_add = { aceptada: false, pieza: pieza };
        setPiezas([...piezas, pieza_to_add]);
      }
      setIsLoading(false);
    }
  };

  const showErrorMessage = (text) => {
    setErrorMessage(text);
    setCodigoPieza('');
  };

  const handleRemovePieza = (index) => {
    const newPiezas = [...piezas];
    newPiezas.splice(index, 1);
    setPiezas(newPiezas);
  };

  const handleAcceptPieza = (index) => {
    const newPiezas = [...piezas];
    newPiezas[index].aceptada = true;
    setPiezas(newPiezas);
  };

  const countAceptadas = () => {
    return piezas.filter((pieza) => pieza.aceptada).length;
  };

  const handleProcesoPiezas = (aceptadas = false) => {
    var piezas_map = [];
    if (aceptadas) {
      piezas_map = piezas
        .filter((pieza) => pieza.aceptada)
        .map((pieza) => pieza.pieza.id);
      if (piezas_map.length === 0) {
        toast.warning('No hay piezas seleccionadas para aceptar.');
        return;
      }
    } else {
      piezas_map = piezas.map((pieza) => pieza.pieza.id);
    }
    const data = { piezas: piezas_map, user_id: currentUser.id };
    procesaPiezasAceptadas(data);
  };

  const procesaPiezasAceptadas = async (piezas) => {
    setIsProcessing(true);
    try {
      const res = await API_PIEZAS.procesarPiezasAceptadas(piezas);
      if (res.success) {
        toast.success('Piezas aceptadas correctamente.');
        setPiezas([]);
        onClose();
      }
    } catch (error) {
      toast.error('Error al procesar las piezas aceptadas.');
    } finally {
      setIsProcessing(false);
    }
  };

  const fetchPieza = async () => {
    try {
      const res = await API_PIEZAS.fetchReferencia(codigoPieza);
      if (res.success === true) {
        if (res.multiple) {
          return await new Promise((resolve) => {
            setConfirmCallback(() => (confirm) => {
              setConfirmModalOpen(false);
              resolve(confirm ? res.pieza : null);
            });
            setConfirmModalOpen(true);
          });
        }
        return res.pieza;
      }
      throw Error('Failed to fetch pieza');
    } catch (error) {
      const msg_error =
        error.response?.data?.message ||
        `No se encontró la pieza: ${codigoPieza}.`;
      showErrorMessage(msg_error);
      return null;
    }
  };

  const handleConfirmAction = (confirm) => {
    if (confirmCallback) confirmCallback(confirm);
  };

  useEffect(() => {
    setTimeout(() => {
      setCodigoPieza('');
      setPiezas([]);
      setErrorMessage('');
      if (inputCodigo.current && state) {
        inputCodigo.current.focus();
      }
    }, 100);
  }, [state]);

  return (
    <Dialog
      open={state}
      onClose={onClose}
      fullWidth={true}
      maxWidth={width ? width : 'sm'}
      aria-labelledby="info-title"
      aria-describedby="info-description"
    >
      <DialogTitle id="info-title">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <h3 className="text-uppercase fw-bold">Lector de código de barras</h3>
          <span title="cerrar" className="close" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} size="1x" color="#215732" />
          </span>
        </div>
      </DialogTitle>
      <DialogContent>
        <h6 className="mb-4" style={{ fontSize: '16px' }}>
          Con el lector de código de barras, escanea el pedido. Una vez veas
          reflejado en el campo de texto inferior el número de pedido, haz click
          en siguiente.
        </h6>
        <div className="d-flex justify-content-center align-items-center">
          <input
            type="text"
            className={`form-control ${errorMessage ? 'border-danger' : ''}`}
            placeholder="Escanear código de barras"
            value={codigoPieza}
            onKeyDown={(e) => handleEnterInput(e)}
            onChange={(e) => setCodigoPieza(e.target.value)}
            ref={inputCodigo}
          />
          {isLoading ? (
            <FontAwesomeIcon className="ms-4" icon={faSpinner} size="2x" spin />
          ) : (
            <Search
              cursor="pointer"
              className="ms-4"
              style={{ color: '$primary' }}
              onClick={() => handleEnterInput({ key: 'Enter' })}
            />
          )}
        </div>
        {errorMessage && (
          <div className="mt-2">
            <ErrorOutline className="me-2" style={{ color: 'red' }} />
            <small className="text-danger">{errorMessage}</small>
          </div>
        )}
        {piezas.length > 0 ? (
          <div>
            <div className="mt-4">
              <p className="mb-2">
                <b>
                  <u>Piezas escaneadas</u>
                </b>
              </p>
              {piezas.map((item, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between mb-2"
                >
                  <span>{item.pieza.nombre}</span>
                  {item.aceptada ? (
                    <p className="pieza-aceptada">Aceptada</p>
                  ) : (
                    <div className="d-flex align-items-center">
                      <CheckCircleOutline
                        onClick={() => handleAcceptPieza(index)}
                        style={{
                          color: 'green',
                          marginRight: '8px',
                          cursor: 'pointer',
                        }}
                      />
                      <Cancel
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => handleRemovePieza(index)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn btn-primary me-2"
                onClick={() => handleProcesoPiezas(true)}
                disabled={isProcessing} // Disable during processing
              >
                {isProcessing ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  `Aceptar seleccionados (${countAceptadas()})`
                )}
              </button>
              <button
                className="btn btn-primary"
                onClick={() => handleProcesoPiezas()}
                disabled={isProcessing} // Disable during processing
              >
                {isProcessing ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  'Aceptar todos'
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="mt-4">
            <i>No hay piezas escaneadas.</i>
          </div>
        )}
      </DialogContent>
      <ConfirmModal
        title={'Confirmar escaneo'}
        description={
          'Esta pieza pertenece a un pedido con más de una pieza. ¿Desea proceder con la aceptación de la pieza?'
        }
        state={confirmModalOpen}
        onConfirmAction={handleConfirmAction}
        onClose={() => setConfirmModalOpen(false)}
      />
    </Dialog>
  );
}
