import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

export default function ConfirmModal({
  title,
  description,
  state,
  onConfirmAction,
  onClose,
}) {
  const handleConfirm = (confirm) => {
    onConfirmAction(confirm);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={state}
      onClose={handleClose}
      aria-labelledby="confirm-title"
      aria-describedby="confirm-description"
    >
      <DialogTitle id="confirm-title">
        <p className="text-uppercase fw-bold">{title}</p>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleConfirm(true)}
        >
          Si
        </button>
        <button
          type="button"
          className="btn btn-default"
          onClick={() => handleConfirm(false)}
        >
          No
        </button>
      </DialogActions>
    </Dialog>
  );
}
