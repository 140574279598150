import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'signature_pad';
import { Box, Button, Typography } from '@mui/material';

export default function Step3({ setFirma, setCanProceed }) {
  const canvasRef = useRef(null);
  const signaturePadRef = useRef(null);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);

  const handleBeginStroke = () => {
    setIsCanvasEmpty(false);
  };

  // Listener para validar la firma cuando el usuario termina de dibujar
  const handleEndStroke = () => {
    validateSignature();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    signaturePadRef.current = new SignaturePad(canvas);

    // Listener para detectar cuando el usuario comienza a dibujar
    signaturePadRef.current.addEventListener('beginStroke', handleBeginStroke);
    signaturePadRef.current.addEventListener('endStroke', handleEndStroke);

    return () => {
      signaturePadRef.current.removeEventListener(
        'beginStroke',
        handleBeginStroke
      );
      signaturePadRef.current.removeEventListener('endStroke', handleEndStroke);
    };
  }, []);

  const calculateSignatureLength = (points) => {
    let length = 0;
    for (let i = 1; i < points.length; i++) {
      const dx = points[i].x - points[i - 1].x;
      const dy = points[i].y - points[i - 1].y;
      length += Math.sqrt(dx * dx + dy * dy);
    }
    return length;
  };

  const validateSignature = () => {
    if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
      const points = signaturePadRef.current.toData();
      const totalLength = points.reduce(
        (acc, stroke) => acc + calculateSignatureLength(stroke.points),
        0
      );
      const totalPoints = points.reduce(
        (acc, stroke) => acc + stroke.points.length,
        0
      );

      if (totalPoints > 20 && totalLength > 500) {
        const dataURL = signaturePadRef.current.toDataURL('image/png');
        setFirma(dataURL);
        setCanProceed(true);
      } else {
        setCanProceed(false);
      }
    } else {
      setCanProceed(false);
    }
  };

  const clearSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
      setFirma(null);
      setCanProceed(false);
      setIsCanvasEmpty(true);
    }
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h5" align="center" gutterBottom>
        Firma
      </Typography>
      <Box
        sx={{
          border: '1px solid #000',
          width: '100%',
          height: '200px',
          marginBottom: '20px',
        }}
      >
        <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
      </Box>
      <Button
        variant="contained"
        onClick={clearSignature}
        sx={{ mr: 2 }}
        hidden={isCanvasEmpty}
      >
        Clear
      </Button>
    </Box>
  );
}
