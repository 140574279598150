import { HideImage } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatUrlImage } from '../../../shared/helpers/functionalities';

export default function PiezaPreview({
  pieza,
  onOpenModal,
}) {
  const cdnServices = useSelector(state => {
    const { cdn_services } = state;
    return cdn_services;
  });
  const [imageData, setImageData] = useState(null);

  const fetchImageData = async () => {
    try {
      const firstEntry = pieza.imagenes[0];
      if (!firstEntry) throw new Error('No images found');

      const url_img = formatUrlImage(cdnServices, firstEntry);
      const isDesmontadas = url_img.includes('api/imagesDesmontadas/');

      setImageData(isDesmontadas ? pieza.base64img : url_img || null);
    } catch (error) {
      setImageData(null);
    }
  };

  useEffect(() => {
    fetchImageData();
  }, [pieza]);

  return (
    <div className="d-flex justify-content-center">
      {imageData ? (
        <img
          alt={`Imagen pieza ${pieza.nombre}`}
          src={imageData}
          style={{ width: '100px' }}
          onClick={() => onOpenModal(pieza)}
        />
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <HideImage
            style={{ color: '#a9a9a9', width: '100px', height: 'auto' }}
          />
          <small>
            <i>Sin imagen</i>
          </small>
        </div>
      )}
    </div>
  );
}
