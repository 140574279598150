export const getActions = () => {
  return [
    {
      label: 'Editar',
      icon: 'edit',
      action: 'edit',
    },
    {
      label: 'Más información',
      icon: 'info',
      action: 'info',
    }
  ];
};
